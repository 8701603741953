var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('studio-card',{attrs:{"studio":_vm.studio}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-simple-table',{staticClass:"horario"},[_c('thead',[_c('tr',_vm._l(((1, 7)),function(n){return _c('th',{key:n,attrs:{"width":n === 3 ? 300 : 250}},[_vm._v(" "+_vm._s(_vm.$t("week_days")[n])+" ")])}),0)]),(_vm.max)?_c('tbody',_vm._l(((1, _vm.max)),function(n){return _c('tr',{key:n},_vm._l(((1, 7)),function(m){return _c('td',{key:m},[(_vm.studio.timetable[m - 1][n - 1])?_c('span',[_c('td',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.studio.timetable[m - 1][n - 1][0])+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.studio.timetable[m - 1][n - 1][1])+" ")])])]):_c('span',[_vm._v("-")])])}),0)}),0):_vm._e()])],1)],1),_c('v-data-table',{attrs:{"footer-props":{
        'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
          ? ''
          : _vm.$t('items_per_page'),
      },"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.customer.user.first_name)+" "+_vm._s(item.customer.user.last_name))]}},{key:"item.tattooer",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.tattooer.user.first_name)+" "+_vm._s(item.tattooer.user.last_name))]}},{key:"item.studio",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.studio.user.first_name)+" "+_vm._s(item.studio.user.last_name))]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [(item.available_dates.length > 0)?_c('span',[_vm._v(_vm._s(_vm.$browserDetect.isSafari || _vm.$browserDetect.meta.name === "Safari" ? _vm.$d( new Date(item.available_dates[0].date.substring(0, 10)), "date2digits" ) + " " + item.available_dates[0].date.substring(11, 16) : _vm.$d(new Date(item.available_dates[0].date), "short")))]):_c('span',[_vm._v("-")])]}},{key:"item.price",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.$n(item.price, "currency")))]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'appointmentView',
              params: { id: item.id },
            })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$eye")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }