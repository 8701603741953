<template>
  <v-container fluid>
    <base-material-card>
      <v-btn
        color="primary"
        @click.native="$router.back()"
        style="height: 30px"
        outlined
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-row align="center">
        <v-col cols="12" md="6">
          <studio-card :studio="studio"> </studio-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-simple-table class="horario">
            <thead>
              <tr>
                <th v-for="n in (1, 7)" :width="n === 3 ? 300 : 250" :key="n">
                  {{ $t("week_days")[n] }}
                </th>
              </tr>
            </thead>

            <tbody v-if="max">
              <tr v-for="n in (1, max)" :key="n">
                <td v-for="m in (1, 7)" :key="m">
                  <span v-if="studio.timetable[m - 1][n - 1]">
                    <td>
                      <p class="mb-1">
                        {{ studio.timetable[m - 1][n - 1][0] }}
                      </p>
                      <p class="mb-0">
                        {{ studio.timetable[m - 1][n - 1][1] }}
                      </p>
                    </td>
                  </span>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-data-table
        :footer-props="{
          'items-per-page-text': $vuetify.breakpoint.smAndDown
            ? ''
            : $t('items_per_page'),
        }"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
      >
        <template v-slot:item.customer="{ item }"
          >{{ item.customer.user.first_name }}
          {{ item.customer.user.last_name }}</template
        >
        <template v-slot:item.tattooer="{ item }"
          >{{ item.tattooer.user.first_name }}
          {{ item.tattooer.user.last_name }}</template
        >
        <template v-slot:item.studio="{ item }"
          >{{ item.studio.user.first_name }}
          {{ item.studio.user.last_name }}</template
        >
        <template v-slot:item.date="{ item }"
          ><span v-if="item.available_dates.length > 0">{{
            $browserDetect.isSafari || $browserDetect.meta.name === "Safari"
              ? $d(
                  new Date(item.available_dates[0].date.substring(0, 10)),
                  "date2digits"
                ) +
                " " +
                item.available_dates[0].date.substring(11, 16)
              : $d(new Date(item.available_dates[0].date), "short")
          }}</span
          ><span v-else>-</span></template
        >
        <template v-slot:item.price="{ item }">{{
          $n(item.price, "currency")
        }}</template>
        <template v-slot:item.actions="{ item }"
          ><v-btn
            icon
            @click="
              $router.push({
                name: 'appointmentView',
                params: { id: item.id },
              })
            "
          >
            <v-icon small>$eye</v-icon></v-btn
          ></template
        >
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "StudioView",
  data() {
    return {
      studio: {},
      items: [],
      options: {},
    };
  },
  components: {
    StudioCard: () => import("@/components/studio/Card"),
  },

  watch: {
    options: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchStudios();
    this.fetchAppointments();
  },
  methods: {
    ...mapActions("studios", ["getStudio"]),
    ...mapActions("appointments", ["getAppointments"]),

    fetchStudios() {
      this.getStudio(this.$route.params.id).then((studio) => {
        this.studio = studio;
      });
    },

    fetchAppointments() {
      this.loading = true;
      this.getAppointments({
        pagination: this.options,
        filters: {
          studio_id: this.$route.params.id,
          tattooer_id: this.$store.state.auth.user.tattooer.id,
        },
      }).then((appointments) => {
        this.items = appointments.data;
        this.totalAppointments = appointments.total;
        this.loading = false;
      });
    },
  },
  computed: {
    max() {
      let lengths = this.studio.timetable.map((t) => t.length);

      return Math.max(...lengths);
    },
    headers() {
      return [
        {
          text: this.$tc("customer"),
          align: "center",
          sortable: false,
          value: "customer",
        },
        {
          text: this.$tc("artist"),
          align: "center",
          sortable: false,
          value: "tattooer",
        },
        {
          text: this.$t("appointments.price"),
          align: "center",
          sortable: false,
          value: "price",
        },
        {
          text: this.$t("appointments.date"),
          align: "center",
          sortable: false,
          value: "date",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
};
</script>

<style lang="sass">
.horario
  table
    min-width: 660px
</style>